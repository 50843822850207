import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  BROWSER_EXT_MESSAGE_TYPE,
  BrowserExtMessagePayloadSfNavigation,
  O8_BROWSER_EXTENSION,
} from 'common.interfaces/browser-ext';
import { WINDOW } from '../../shared-providers';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { EMPTY, Observable, map, of, scan, switchMap, take, tap, timer } from 'rxjs';
import { SnackMessageService } from '../snack-message';
import { withoutTrailingSlash } from '@origin8-web/o8-utils/format';
import { isProdHostname } from '@origin8-web/o8-utils/helper';

@Injectable({
  providedIn: 'root',
})
export class BrowserExtApiService {
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(PLATFORM_ID) private platformId: NonNullable<unknown>,
    private snackMessageService: SnackMessageService,
  ) {}

  private sendMessageToExtension(type: BROWSER_EXT_MESSAGE_TYPE, payload: object) {
    this.window.postMessage({ type, data: payload });
  }

  private isExtensionInstalled(): boolean {
    const el = this.window.document.getElementById(O8_BROWSER_EXTENSION);
    return !!el;
  }

  triggerSfNavigation(taskId: string, basePath: string, taskType: 'Task' | 'Event' = 'Task') {
    if (isPlatformBrowser(this.platformId)) {
      const payload: BrowserExtMessagePayloadSfNavigation = {
        path: `/lightning/r/${taskType}/${taskId}/view`,
        basePath,
      };
      if (this.isExtensionInstalled()) {
        this.sendMessageToExtension(BROWSER_EXT_MESSAGE_TYPE.SF_NAVIGATION, payload);
      } else {
        console.warn('No o8 chrome extension installed, opening new tab');
        this.window.open(`${withoutTrailingSlash(basePath)}${payload.path}`, '_blank');
      }
    }
  }

  checkExtensionIsInstalled(maxTries = 3): Observable<boolean> {
    if (isPlatformServer(this.platformId) || !isProdHostname(this.window.location.hostname)) {
      return EMPTY;
    }
    return timer(1, 10000).pipe(
      take(maxTries),
      map(() => this.isExtensionInstalled()),
      scan(
        (acc, o8ExtensionhasRun) => {
          return o8ExtensionhasRun ? { ...acc, o8ExtensionhasRun } : { count: acc.count + 1, o8ExtensionhasRun };
        },
        { count: 0, o8ExtensionhasRun: false },
      ),
      switchMap((acc) => {
        if (acc.o8ExtensionhasRun) {
          return of(true);
        }
        return acc.count >= maxTries ? of(false) : EMPTY;
      }),
      take(1),
    );
  }

  checkExtensionIsInstalledAndDisplayErrorMessageIfNot(maxTries = 3) {
    return this.checkExtensionIsInstalled(maxTries).pipe(
      tap((isInstalled) => {
        if (!isInstalled) {
          this.snackMessageService.showError(
            `The Origin8Cares chrome extension needs to be installed for the app to perform properly`,
          );
        }
      }),
    );
  }
}
